<template>
    <div>
        <system-preloader v-if="loading"></system-preloader>
        <b-row>
            <b-col class="d-flex align-items-center justify-content-between mb-4 column-992">
                <p class="fs-32 grey-color m-0 main_text" style="line-height: 1">Редактирование производимого Товара / Работы / Услуги</p>
            </b-col>
        </b-row>
        <b-form @submit.prevent="onSubmit">
            <b-row>
                <b-col>
                    <b-row class="py-1">
                        <b-col md="4" class="d-flex align-items-center">
                            <span class="fs-14 grey-color">Позиция КТРУ из каталога <span class="red-star">*</span></span>
                        </b-col>
                        <b-col md="8" class="fs-14 grey-color">
                            <b-form-input v-model="formKtru.reg_number" :state="$v.form.ktru_item_id.$invalid && showErrors ? !$v.form.ktru_item_id.$invalid : null" aria-describedby="formdata_ktru_item_id_liveFeedback" disabled />
                            <b-form-invalid-feedback v-if="showErrors && $v.form.ktru_item_id.$invalid" id="formdata_ktru_item_id_liveFeedback" :state="showErrors && $v.form.ktru_item_id.$invalid"> Поле обязательно для заполнения. </b-form-invalid-feedback>
                        </b-col>
                    </b-row>
                    <b-row class="py-1">
                        <b-col md="4" class="d-flex align-items-center">
                            <span class="fs-14 grey-color">Годовой объем производства <span class="red-star">*</span></span>
                        </b-col>
                        <b-col md="8" class="fs-14 grey-color">
                            <b-form-input v-model.number="form.volume" type="number" v-stop-number-mousewheel step="any" :state="$v.form.volume.$invalid && showErrors ? !$v.form.volume.$invalid : null" aria-describedby="formdata_volume_liveFeedback" />
                            <b-form-invalid-feedback v-if="showErrors && $v.form.volume.$invalid" id="formdata_volume_liveFeedback" :state="showErrors && $v.form.volume.$invalid"> Поле обязательно для заполнения. </b-form-invalid-feedback>
                        </b-col>
                    </b-row>
                    <template v-if="form.ktru_item_id">
                        <b-row class="py-1">
                            <b-col md="4" class="d-flex">
                                <span class="fs-14 grey-color">Группа КТРУ</span>
                            </b-col>
                            <b-col md="8" class="fs-14 grey-color">
                                {{ formKtru.ktru_group_name }}
                            </b-col>
                        </b-row>
                        <b-row class="py-1">
                            <b-col md="4" class="d-flex">
                                <span class="fs-14 grey-color">Тип КТРУ</span>
                            </b-col>
                            <b-col md="8" class="fs-14 grey-color">
                                {{ formKtru.ktru_type_name }}
                            </b-col>
                        </b-row>
                        <b-row class="py-1">
                            <b-col md="4" class="d-flex">
                                <span class="fs-14 grey-color">Название</span>
                            </b-col>
                            <b-col md="8" class="fs-14 grey-color">
                                {{ formKtru.name }}
                            </b-col>
                        </b-row>
                        <b-row class="py-1">
                            <b-col md="4" class="d-flex">
                                <span class="fs-14 grey-color">Описание</span>
                            </b-col>
                            <b-col md="8" class="fs-14 grey-color">
                                {{ formKtru.description }}
                            </b-col>
                        </b-row>
                        <b-row class="py-1">
                            <b-col md="4" class="d-flex">
                                <span class="fs-14 grey-color">Единица измерения (ОКЕИ)</span>
                            </b-col>
                            <b-col md="8" class="fs-14 grey-color">
                                {{ formKtru.okei_local_symbol }}
                            </b-col>
                        </b-row>
                        <b-row class="py-1">
                            <b-col md="4" class="d-flex">
                                <span class="fs-14 grey-color">Код ОКПД</span>
                            </b-col>
                            <b-col md="8" class="fs-14 grey-color">
                                {{ formKtru.okpd_code }}
                            </b-col>
                        </b-row>
                        <b-row class="py-1">
                            <b-col md="4" class="d-flex">
                                <span class="fs-14 grey-color">Спецификации</span>
                            </b-col>
                            <b-col md="8" class="fs-14 grey-color">
                                <p v-for="spec in formKtru.specs" :key="spec.name + spec.value">
                                    <b>{{ spec.name }}</b
                                    >: {{ spec.value }}
                                </p>
                            </b-col>
                        </b-row>
                    </template>
                    <b-row class="pt-3">
                        <b-col>
                            <b-button class="mr-3" @click="onCancel" variant="custom-outline-secondary"> Отмена </b-button>
                            <b-button type="submit" variant="custom-green" :disabled="$v.form.$invalid"> Сохранить </b-button>
                        </b-col>
                    </b-row>
                </b-col>
            </b-row>
        </b-form>
        <b-row>
            <div class="d-block text-center w-75 m-auto pb-5"></div>
        </b-row>
    </div>
</template>

<script>
import { validationMixin } from 'vuelidate';
import { required, decimal } from 'vuelidate/lib/validators';
import Productions from '@/services/api/cabinet/provider/Productions';

const initialForm = {
    ktru_item_id: null,
    volume: 0
};

const greaterThanZero = (value) => value > 0;

export default {
    name: 'ProductionEdit',
    mixins: [validationMixin],
    data() {
        return {
            showErrors: false,
            showKtruSelectModal: false,
            form: {
                ...initialForm
            },
            formKtru: {
                reg_number: '-',
                ktru_group_name: '-',
                id: null,
                name: '',
                specs: []
            },
            loading: true
        };
    },
    validations: {
        form: {
            ktru_item_id: { required },
            volume: { required, decimal, minValue: greaterThanZero }
        }
    },
    mounted() {
        this.fillData().then(() => {
            setTimeout(() => {
                this.loading = false;
            }, 100);
        });
    },
    methods: {
        fillData() {
            return Productions.get(this.$route.params.id).then((response) => {
                this.form = response.data.data;
                this.formKtru = response.data.data.ktru_item;
                this.form.ktru_item_id = this.formKtru.id;
            });
        },
        createKtruBasedOnExisting() {
            this.showKtruSelectModal = true;
        },
        cancelKtruSelectModal() {
            this.showKtruSelectModal = false;
        },
        processKtruSelect(selectedKtru) {
            this.showKtruSelectModal = false;
            this.form.ktru_item_id = selectedKtru.id;
            this.formKtru = selectedKtru;
        },
        async onSubmit() {
            await Productions.update(this.form.id, this.form)
                .then(() => {
                    this.pushToast({
                        text: 'Данные успешно сохранены',
                        title: 'Успех',
                        variant: 'green',
                        timer: 5000
                    });
                    this.$router.push({ name: 'ProviderProductionsList' }).catch(() => {});
                })
                .catch((err) => {
                    this.showError(err);
                });
        },
        onCancel() {
            this.$router.push({ name: 'ProviderProductionsList' }).catch(() => {});
        }
    }
};
</script>

<style scoped>
.transparent-card {
    border: none;
    background-color: rgba(1, 1, 1, 0);
}
</style>
