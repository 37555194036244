var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.loading ? _c("system-preloader") : _vm._e(),
      _c(
        "b-row",
        [
          _c(
            "b-col",
            {
              staticClass:
                "d-flex align-items-center justify-content-between mb-4 column-992",
            },
            [
              _c(
                "p",
                {
                  staticClass: "fs-32 grey-color m-0 main_text",
                  staticStyle: { "line-height": "1" },
                },
                [
                  _vm._v(
                    "Редактирование производимого Товара / Работы / Услуги"
                  ),
                ]
              ),
            ]
          ),
        ],
        1
      ),
      _c(
        "b-form",
        {
          on: {
            submit: function ($event) {
              $event.preventDefault()
              return _vm.onSubmit.apply(null, arguments)
            },
          },
        },
        [
          _c(
            "b-row",
            [
              _c(
                "b-col",
                [
                  _c(
                    "b-row",
                    { staticClass: "py-1" },
                    [
                      _c(
                        "b-col",
                        {
                          staticClass: "d-flex align-items-center",
                          attrs: { md: "4" },
                        },
                        [
                          _c("span", { staticClass: "fs-14 grey-color" }, [
                            _vm._v("Позиция КТРУ из каталога "),
                            _c("span", { staticClass: "red-star" }, [
                              _vm._v("*"),
                            ]),
                          ]),
                        ]
                      ),
                      _c(
                        "b-col",
                        { staticClass: "fs-14 grey-color", attrs: { md: "8" } },
                        [
                          _c("b-form-input", {
                            attrs: {
                              state:
                                _vm.$v.form.ktru_item_id.$invalid &&
                                _vm.showErrors
                                  ? !_vm.$v.form.ktru_item_id.$invalid
                                  : null,
                              "aria-describedby":
                                "formdata_ktru_item_id_liveFeedback",
                              disabled: "",
                            },
                            model: {
                              value: _vm.formKtru.reg_number,
                              callback: function ($$v) {
                                _vm.$set(_vm.formKtru, "reg_number", $$v)
                              },
                              expression: "formKtru.reg_number",
                            },
                          }),
                          _vm.showErrors && _vm.$v.form.ktru_item_id.$invalid
                            ? _c(
                                "b-form-invalid-feedback",
                                {
                                  attrs: {
                                    id: "formdata_ktru_item_id_liveFeedback",
                                    state:
                                      _vm.showErrors &&
                                      _vm.$v.form.ktru_item_id.$invalid,
                                  },
                                },
                                [_vm._v(" Поле обязательно для заполнения. ")]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-row",
                    { staticClass: "py-1" },
                    [
                      _c(
                        "b-col",
                        {
                          staticClass: "d-flex align-items-center",
                          attrs: { md: "4" },
                        },
                        [
                          _c("span", { staticClass: "fs-14 grey-color" }, [
                            _vm._v("Годовой объем производства "),
                            _c("span", { staticClass: "red-star" }, [
                              _vm._v("*"),
                            ]),
                          ]),
                        ]
                      ),
                      _c(
                        "b-col",
                        { staticClass: "fs-14 grey-color", attrs: { md: "8" } },
                        [
                          _c("b-form-input", {
                            directives: [
                              {
                                name: "stop-number-mousewheel",
                                rawName: "v-stop-number-mousewheel",
                              },
                            ],
                            attrs: {
                              type: "number",
                              step: "any",
                              state:
                                _vm.$v.form.volume.$invalid && _vm.showErrors
                                  ? !_vm.$v.form.volume.$invalid
                                  : null,
                              "aria-describedby":
                                "formdata_volume_liveFeedback",
                            },
                            model: {
                              value: _vm.form.volume,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "volume", _vm._n($$v))
                              },
                              expression: "form.volume",
                            },
                          }),
                          _vm.showErrors && _vm.$v.form.volume.$invalid
                            ? _c(
                                "b-form-invalid-feedback",
                                {
                                  attrs: {
                                    id: "formdata_volume_liveFeedback",
                                    state:
                                      _vm.showErrors &&
                                      _vm.$v.form.volume.$invalid,
                                  },
                                },
                                [_vm._v(" Поле обязательно для заполнения. ")]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm.form.ktru_item_id
                    ? [
                        _c(
                          "b-row",
                          { staticClass: "py-1" },
                          [
                            _c(
                              "b-col",
                              { staticClass: "d-flex", attrs: { md: "4" } },
                              [
                                _c(
                                  "span",
                                  { staticClass: "fs-14 grey-color" },
                                  [_vm._v("Группа КТРУ")]
                                ),
                              ]
                            ),
                            _c(
                              "b-col",
                              {
                                staticClass: "fs-14 grey-color",
                                attrs: { md: "8" },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.formKtru.ktru_group_name) +
                                    " "
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                        _c(
                          "b-row",
                          { staticClass: "py-1" },
                          [
                            _c(
                              "b-col",
                              { staticClass: "d-flex", attrs: { md: "4" } },
                              [
                                _c(
                                  "span",
                                  { staticClass: "fs-14 grey-color" },
                                  [_vm._v("Тип КТРУ")]
                                ),
                              ]
                            ),
                            _c(
                              "b-col",
                              {
                                staticClass: "fs-14 grey-color",
                                attrs: { md: "8" },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.formKtru.ktru_type_name) +
                                    " "
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                        _c(
                          "b-row",
                          { staticClass: "py-1" },
                          [
                            _c(
                              "b-col",
                              { staticClass: "d-flex", attrs: { md: "4" } },
                              [
                                _c(
                                  "span",
                                  { staticClass: "fs-14 grey-color" },
                                  [_vm._v("Название")]
                                ),
                              ]
                            ),
                            _c(
                              "b-col",
                              {
                                staticClass: "fs-14 grey-color",
                                attrs: { md: "8" },
                              },
                              [_vm._v(" " + _vm._s(_vm.formKtru.name) + " ")]
                            ),
                          ],
                          1
                        ),
                        _c(
                          "b-row",
                          { staticClass: "py-1" },
                          [
                            _c(
                              "b-col",
                              { staticClass: "d-flex", attrs: { md: "4" } },
                              [
                                _c(
                                  "span",
                                  { staticClass: "fs-14 grey-color" },
                                  [_vm._v("Описание")]
                                ),
                              ]
                            ),
                            _c(
                              "b-col",
                              {
                                staticClass: "fs-14 grey-color",
                                attrs: { md: "8" },
                              },
                              [
                                _vm._v(
                                  " " + _vm._s(_vm.formKtru.description) + " "
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                        _c(
                          "b-row",
                          { staticClass: "py-1" },
                          [
                            _c(
                              "b-col",
                              { staticClass: "d-flex", attrs: { md: "4" } },
                              [
                                _c(
                                  "span",
                                  { staticClass: "fs-14 grey-color" },
                                  [_vm._v("Единица измерения (ОКЕИ)")]
                                ),
                              ]
                            ),
                            _c(
                              "b-col",
                              {
                                staticClass: "fs-14 grey-color",
                                attrs: { md: "8" },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.formKtru.okei_local_symbol) +
                                    " "
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                        _c(
                          "b-row",
                          { staticClass: "py-1" },
                          [
                            _c(
                              "b-col",
                              { staticClass: "d-flex", attrs: { md: "4" } },
                              [
                                _c(
                                  "span",
                                  { staticClass: "fs-14 grey-color" },
                                  [_vm._v("Код ОКПД")]
                                ),
                              ]
                            ),
                            _c(
                              "b-col",
                              {
                                staticClass: "fs-14 grey-color",
                                attrs: { md: "8" },
                              },
                              [
                                _vm._v(
                                  " " + _vm._s(_vm.formKtru.okpd_code) + " "
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                        _c(
                          "b-row",
                          { staticClass: "py-1" },
                          [
                            _c(
                              "b-col",
                              { staticClass: "d-flex", attrs: { md: "4" } },
                              [
                                _c(
                                  "span",
                                  { staticClass: "fs-14 grey-color" },
                                  [_vm._v("Спецификации")]
                                ),
                              ]
                            ),
                            _c(
                              "b-col",
                              {
                                staticClass: "fs-14 grey-color",
                                attrs: { md: "8" },
                              },
                              _vm._l(_vm.formKtru.specs, function (spec) {
                                return _c(
                                  "p",
                                  { key: spec.name + spec.value },
                                  [
                                    _c("b", [_vm._v(_vm._s(spec.name))]),
                                    _vm._v(": " + _vm._s(spec.value) + " "),
                                  ]
                                )
                              }),
                              0
                            ),
                          ],
                          1
                        ),
                      ]
                    : _vm._e(),
                  _c(
                    "b-row",
                    { staticClass: "pt-3" },
                    [
                      _c(
                        "b-col",
                        [
                          _c(
                            "b-button",
                            {
                              staticClass: "mr-3",
                              attrs: { variant: "custom-outline-secondary" },
                              on: { click: _vm.onCancel },
                            },
                            [_vm._v(" Отмена ")]
                          ),
                          _c(
                            "b-button",
                            {
                              attrs: {
                                type: "submit",
                                variant: "custom-green",
                                disabled: _vm.$v.form.$invalid,
                              },
                            },
                            [_vm._v(" Сохранить ")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                2
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("b-row", [
        _c("div", { staticClass: "d-block text-center w-75 m-auto pb-5" }),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }